<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-select
            v-model="type_of_search"
            dense
            outlined
            label="Type of Search"
            :items="['From Collector','Search Individually']"
            :rules="rules.combobox_rule"
          ></v-select>
          <v-select
            v-if="type_of_search==='From Collector'"
            v-model="collector_id"
            dense
            outlined
            label="From Collector"
            :items="collector_items"
            item-value="id"
            item-text="full_name"
            :rules="rules.combobox_rule"
            @change="selected_from_collector"
          ></v-select>
          <v-text-field
            v-if="type_of_search==='Search Individually'"
            label="Search Data"
            placeholder="Press Enter to Search"
            required
            v-model="search_data"
            @keydown.enter="search_member_have_loan"
          ></v-text-field>
          <v-data-table dense
                        :headers="headers"
                        :items="loans_item"
          >
            <template v-slot:item="{ item }">
              <tr
                @click="activerow(item)"
                :class="{'info black--text': item.loan_id===selectedDepositId}"
              >
                <td>
                  {{ item.full_name}}
                </td>
                <td>
                  {{ item.type_of_loan}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" md="8">
          <div class="mt-4">
            <v-row>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-select
                  v-model="new_collector_id"
                  dense
                  outlined
                  label="Collector To Be Transfer"
                  :items="collector_only_items"
                  item-value="id"
                  item-text="full_name"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-btn
                  color="primary"
                  @click="transfer_save"
                  v-if="!saving"
                >
                  Transfer Save
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-row>
            <h2>
              Data To Be Transfer
            </h2>
            <v-data-table dense
                          item-key="id"
                          :headers="headers2"
                          :items="new_loans_data"
                          disable-pagination
                          hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-checkbox
                      @change="selected_loan(item)"></v-checkbox>
                  </td>
                  <td>
                    {{ item.laf_no!=null?item.laf_no:item.glaf_no}}
                  </td>
                  <td>
                    {{ item.members!=null?item.members.last_name+', '+item.members.first_name:''}}
                  </td>
                  <td>
                    {{ item.type_of_loan}}
                  </td>
                  <td class="text-center" v-if="is_can_delete">
                    <v-icon
                      v-if="!is_deleting"
                      class="mr-2"
                      color="error"
                      @click="delete_menus_order(item,new_loans_data.map(function (x) {
          return x.id
        }).indexOf(item.id))"
                    >
                      {{icons.mdiDelete}}
                    </v-icon>
                    <v-progress-circular color="info" indeterminate
                                         v-else></v-progress-circular>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiPrinter,
    mdiToggleSwitchOff,
    mdiToggleSwitch,
    mdiAlertOutline,
    mdiDelete
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',


      type_of_search: '',
      is_deleting: false,
      is_can_delete: true,
      selectedDepositId: -1,
      selected: [],
      loans_item: [],
      new_loans_data: [],
      collector_items: [],
      collector_only_items: [],
      collector_id: '',
      new_collector_id: '',
      search_data: '',
      headers: [
        {text: 'NAME', value: 'members.last_name',},
        {text: 'CATEGORY', value: 'members.last_name',},
      ],
      headers2: [
        {text: 'SELECT', value: 'laf_no', sortable: false},
        {text: 'LAF #', value: 'laf_no', sortable: false},
        {text: 'NAME', value: 'members.last_name'},
        {text: 'CATEGORY', value: 'members.last_name'},
        {text: 'Action', value: 'action', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiToggleSwitchOff,
          mdiAlertOutline,
          mdiDelete,
          mdiToggleSwitch
        },
      }
    },
    mounted() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('staff', ['active_collector', 'collector_have_active_loan_assigned']),
      ...mapActions('loans_data', ['transfer_loans_to_collector']),
      ...mapActions('members_information', ['member_search_have_active_loan_assigned']),
      initialize_data() {
        this.selected = []
        const data2 = new FormData()
        data2.append('branch_id', this.branch_id);
        this.collector_have_active_loan_assigned(data2)
          .then(response => {
            this.collector_items = response.data
          })
          .catch(error => {
            console.log(error)
          })

        const data = new FormData()
        data.append('branch_id', this.branch_id);
        this.active_collector(data)
          .then(response => {
            this.collector_only_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_from_collector() {
        this.new_loans_data = []
        var index = this.collector_items.map(function (x) {
          return x.id
        }).indexOf(this.collector_id)
        if (index != -1) {
          var loans = this.collector_items[index].loans
          var gloans = this.collector_items[index].gloans
          var arraaa = []
          loans.forEach(function (item) {
            arraaa.push({
              id: item.id,
              laf_no: item.laf_no,
              glaf_no: null,
              members: item.members,
              type_of_loan: item.type_of_loan,
            })
          })
          gloans.forEach(function (item) {
            arraaa.push({
              id: item.id,
              laf_no: null,
              glaf_no: item.glaf_no,
              members: item.members,
              type_of_loan: item.type_of_loan,
            })
          })
          this.new_loans_data = arraaa
        }
      },
      search_member_have_loan() {
        const data2 = new FormData()
        data2.append('search_word', this.search_data);
        this.member_search_have_active_loan_assigned(data2)
          .then(response => {
            this.loans_item = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      delete_menus_order(item, index) {
        this.new_loans_data.splice(index, 1)
      },
      activerow(item) {
        this.selectedDepositId = item.loan_id
        this.new_loans_data.push({
          id: item.id,
          laf_no: item.laf_no,
          glaf_no: item.glaf_no,
          members: item.members,
          type_of_loan: item.type_of_loan,
        })
      },
      selected_loan(item) {
        var proceed = true;
        for (var x = 0; x < this.selected.length; x++) {
          if (item.id === this.selected[x].id) {
            proceed = false;
            this.selected.splice(x, 1)
          }
        }
        if (proceed) {
          this.selected.push({
            id: item.id,
            laf_no: item.laf_no,
            glaf_no: item.glaf_no,
            members: item.members,
            type_of_loan: item.type_of_loan,
          })
        }
      },
      transfer_save() {
        this.saving = true
        this.alert = false

        const data = new FormData()
        data.append('details', JSON.stringify(this.new_loans_data));
        data.append('details2', JSON.stringify(this.selected));
        data.append('collector_id', this.new_collector_id);
        this.transfer_loans_to_collector(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            Object.assign(this.$data, initialState())
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      },
    }
  }
</script>
